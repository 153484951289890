// DynamicTable.tsx

import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  AutoComplete,
  AutoCompleteProps,
  Button,
  Input,
  InputNumber,
  Table,
  Tooltip,
  Typography,
} from 'antd';

import { InputForm } from '@/components/form/Input';
import { InputNumberForm } from '@/components/form/InputNumber';
import { SelectForm } from '@/components/form/Select';
import { useAppSelector } from '@/core/redux/hooks';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

import { resolverColumns } from './resolver/columns';

import styles from './styles.module.scss';
const { TextArea } = Input;

interface RowData {
  Mi: number;
  mi: number;
  Qp_ni: number;
}

const { Text, Title } = Typography;

interface IDynamicAerodynamicsTable {
  handleChange: () => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  isObserver: boolean;
}

export const DynamicAerodynamicsTable: React.FC<IDynamicAerodynamicsTable> = ({
  handleChange,
  handleBlur,
  isObserver,
}) => {
  const { control, reset, setValue, getValues } = useFormContext();
  const systemState = useAppSelector(systemSelectors.systemState);

  const [isHideSupport, setIsHideSupport] = useState<boolean>(false);
  const [isRoundingValues, setIsRoundingValues] = useState<boolean>(false);
  const [activeTooltip, setActiveTooltip] = useState<string>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'result_table',
  });

  const handleAddRow = () => {
    const nullValuesObject: Record<string, null> = {};

    resolverColumns.forEach((column) => {
      nullValuesObject[column.name] = null;
    });
    append({
      ...nullValuesObject,
      i: fields.length + 1,
    });
    handleChange();
  };

  const handleCopyRow = (index: number) => {
    const rowToCopy: Record<string, string> = fields[index]; // Get the row to copy

    append({
      ...rowToCopy,
      G_nach_uch: rowToCopy['G_kon_uch'],
      t_nach_uch: rowToCopy['t_kon_uch'],
      i: fields.length + 1,
    });
    handleChange();
  };

  const handleRemoveRow = (index: number) => {
    remove(index);
    handleChange();
  };

  const filteredColumns = useMemo(
    () =>
      resolverColumns.filter((item: any) => {
        if (isHideSupport) {
          return item.editable;
        }

        return true;
      }),
    [resolverColumns, isHideSupport, isRoundingValues]
  );

  useEffect(() => {
    reset(systemState);
  }, [isHideSupport]);

  const [autoCompleteOptions] = useState<AutoCompleteProps['options']>([
    {
      key: 'Листовая сталь',
      value: '0.1',
      label: 'Листовая сталь - 0,1мм',
    },
    {
      key: 'Винипласт',
      value: '0.1',
      label: 'Винипласт - 0,1мм',
    },
    {
      key: 'Асбестоцементные плиты или трубы',
      value: '0.11',
      label: 'Асбестоцементные плиты или трубы - 0,11мм',
    },
    {
      key: 'Шлакоалебастровые плиты',
      value: '1',
      label: 'Шлакоалебастровые плиты - 1мм',
    },
    {
      key: 'Шлакобетонные плиты',
      value: '1.5',
      label: 'Шлакобетонные плиты - 1,5мм',
    },
    {
      key: 'Кирпич',
      value: '4',
      label: 'Кирпич - 4мм',
    },
    {
      key: 'Штукатурка (по металлической сетке)',
      value: '10',
      label: 'Штукатурка (по металлической сетке) - 10мм',
    },
  ]);

  const columnValues = filteredColumns.map((item, index) => {
    switch (item.type) {
      case 'Ke':
        return {
          title: (
            <Tooltip
              placement='top'
              title={item.tooltip}
              open={item.name === activeTooltip}
              zIndex={1000}
            >
              <i
                className='cursor-default p-2 whitespace-nowrap block'
                onMouseEnter={() => setActiveTooltip(item.name)}
              >
                {item.title}
              </i>
            </Tooltip>
          ),
          dataIndex: item.name,
          render: (text: string, record: any, index: number) => (
            <div
              className='w-[90px] p-2'
              onMouseEnter={() => setActiveTooltip(item.name)}
            >
              <Controller
                render={({ field }) => (
                  <AutoComplete
                    options={autoCompleteOptions}
                    style={{ width: 370 }}
                    allowClear={false}
                    onSelect={(value) => {
                      setValue(`result_table[${index}].${item.name}`, value);
                      handleChange();
                    }}
                    value={field.value}
                    disabled={isObserver}
                  >
                    <Input
                      {...field}
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        handleBlur(event);
                      }}
                      className='!w-[70px]'
                      onKeyDown={(e) => {
                        if (e.key === ',') {
                          e.preventDefault(); // Prevent the comma from being typed
                        }
                      }}
                      disabled={isObserver}
                    />
                  </AutoComplete>
                )}
                control={control}
                name={`result_table[${index}].${item.name}`}
              />
            </div>
          ),
        };
      case 'enum':
        const selectOptions = item?.options
          ? Object.values(item.options).map((item) => ({
              value: item,
              label: item,
            }))
          : [];

        return {
          title: (
            <Tooltip
              placement='top'
              title={item.tooltip}
              open={item.name === activeTooltip}
            >
              <i
                className='cursor-default p-2 block'
                onMouseEnter={() => setActiveTooltip(item.name)}
              >
                {item.title}
              </i>
            </Tooltip>
          ),
          dataIndex: item.name,
          render: (text: string, record: any, index: number) => (
            <div
              className='min-w-[150px] p-2'
              onMouseEnter={() => setActiveTooltip(item.name)}
            >
              <SelectForm
                options={selectOptions}
                name={`result_table[${index}].${item.name}`}
                onChange={handleChange}
                disabled={isObserver}
              />
            </div>
          ),
        };

      default:
        return {
          title: (
            <Tooltip
              placement='top'
              title={item.tooltip}
              open={item.name === activeTooltip}
            >
              <i
                className='cursor-default p-2 whitespace-nowrap block'
                onMouseEnter={() => setActiveTooltip(item.name)}
              >
                {item.title}
              </i>
            </Tooltip>
          ),
          dataIndex: item.name,
          render: (text: string, record: any, index: number) => (
            <div
              className='min-w-[120px] p-2'
              onMouseEnter={() => setActiveTooltip(item.name)}
            >
              <InputNumberForm
                name={`result_table[${index}].${item.name}`}
                onBlur={handleBlur}
                disabled={!item.editable || isObserver}
                size='middle'
                fixValue={isRoundingValues ? 3 : item.precision}
              />
            </div>
          ),
        };
    }
  });

  const columns = [
    {
      title: (
        <Tooltip
          placement='top'
          title='Номер участка'
          open={'Номер участка' === activeTooltip}
        >
          <div
            className=' p-2'
            onMouseEnter={() => setActiveTooltip('Номер участка')}
          >
            <i className='cursor-default'>Уч.</i>
          </div>
        </Tooltip>
      ),
      dataIndex: 'i',
      render: (text: string, record: any, index: number) => (
        <div
          className='p-4'
          onMouseEnter={() => setActiveTooltip('Номер участка')}
        >
          <Text className='text-nowrap'>{index + 1}</Text>
        </div>
      ),
    },
    ...columnValues,
  ];

  return (
    <div className='flex flex-col gap-4'>
      {!isObserver && (
        <div className='flex justify-between'>
          {isHideSupport ? (
            <Button onClick={() => setIsHideSupport(false)} type='primary'>
              Развернуть вспомогательные столбцы
            </Button>
          ) : (
            <Button onClick={() => setIsHideSupport(true)} type='primary'>
              Свернуть вспомогательные столбцы
            </Button>
          )}

          <Button onClick={() => setIsRoundingValues(!isRoundingValues)}>
            Округлять значения
          </Button>
        </div>
      )}
      <div
        onMouseLeave={() => setActiveTooltip(undefined)}
        className='relative'
      >
        <Table
          dataSource={fields}
          columns={columns}
          pagination={false}
          scroll={{ x: 500 }}
          rowKey='i'
          className={styles.aerotable}
        />
      </div>

      {!isObserver && (
        <div className='flex gap-4'>
          <Button onClick={handleAddRow}>Добавить участок</Button>
          <Button onClick={() => handleRemoveRow(fields.length - 1)}>
            Убрать участок
          </Button>
          <Button onClick={() => handleCopyRow(fields.length - 1)}>
            Копировать последний участок
          </Button>
        </div>
      )}
    </div>
  );
};
