import { Controller, useFormContext } from 'react-hook-form';
import { InboxOutlined } from '@ant-design/icons';
import { Typography, Upload, UploadProps } from 'antd';

interface FilesForm extends UploadProps {
  label?: string;
  name: string;
  defaultValue?: string;
}

export const FilesForm: React.FC<FilesForm> = (props) => {
  const { Text } = Typography;

  const { label, name, defaultValue } = props;

  const methods = useFormContext();

  const error: any = methods?.formState?.errors?.['files'];

  const checkError = () => {
    if (error?.type === 'totalFilesSize') {
      return error?.message;
    } else {
      const result = error?.find((el: any) => el?.message)?.message;
      return result;
    }
  };

  return (
    <>
      <div className='flex flex-col gap-1 w-full'>
        {label && (
          <label htmlFor={name} className='text-secondary'>
            {label}
          </label>
        )}
        <Controller
          {...methods}
          render={({ field: { ref, ...restField } }) => {
            return (
              <>
                <Upload.Dragger
                  {...props}
                  multiple
                  beforeUpload={() => false}
                  onChange={(info) => {
                    const newFiles = info.fileList.map((file) => ({
                      originFileObj: file.originFileObj,
                    }));
                    restField.onChange(newFiles);
                  }}
                  listType='picture'
                >
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    <span className='underline'>Нажмите</span> или перетащите
                    файл, чтобы загрузить
                  </p>
                  <p className='ant-upload-hint'>
                    Загрузите файлы (ХОВС или опросные листы), которые
                    необходимы для выполнения запроса на подбор оборудования.
                  </p>
                </Upload.Dragger>
                <Text type='secondary'>
                  Размер одного файла не должен превышать 30 Мб, суммарный
                  размер файлов - не более 100 Мб
                </Text>
              </>
            );
          }}
          name={name}
          defaultValue={defaultValue}
        />

        {checkError() && (
          <Text className='block' type='danger'>
            {checkError()}
          </Text>
        )}
      </div>
    </>
  );
};
