import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  MessageOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Checkbox,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Table,
  TableColumnsType,
  Tooltip,
  Typography,
} from 'antd';

import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { equipmentRequestStatus } from '@/core/enums/equipmentRequestStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { equipmentRequestSelectors } from '@/core/redux/slices/equipment/selectors';
import {
  equipmentRequestActions,
  IEquipmentRequestItem,
} from '@/core/redux/slices/equipment/slice';
import { dateToSystemPage } from '@/core/utils/dateUtils';

import { ToolsPage } from './ToolsPage';

const { Text, Title } = Typography;

const defaultPageSize = 10;

interface Ifilters {
  status?: string;
  search?: string;
  order_by?: string;
  loyalty_program_participant?: boolean;
  has_hovs?: boolean;
  is_urgent?: boolean;
  updated_at__lte?: string;
  updated_at__gte?: string;
  size?: number;
  page?: number;
}

interface ITableHeader {
  handleFilterChange: (key: string, newValue: string | boolean) => void;
  filtersState: Ifilters;
  clearFilters: any;
}

const checkFilters = (filtersState: any) => {
  return (
    filtersState?.has_hovs ||
    filtersState?.is_urgent ||
    filtersState?.search ||
    filtersState?.loyalty_program_participant ||
    filtersState?.status
  );
};

const TableHeader: React.FC<ITableHeader> = ({
  handleFilterChange,
  filtersState,
  clearFilters,
}) => {
  const navigate = useNavigate();

  const items: MenuProps['items'] = [
    {
      label: equipmentRequestStatus.DRAFT,
      key: 'DRAFT',
    },
    {
      label: equipmentRequestStatus.NEW,
      key: 'NEW',
    },

    {
      label: equipmentRequestStatus.IN_PROGRESS,
      key: 'IN_PROGRESS',
    },
    {
      label: equipmentRequestStatus.NEED_INFO,
      key: 'NEED_INFO',
    },
    {
      label: equipmentRequestStatus.COMPLETED,
      key: 'COMPLETED',
    },
    {
      label: equipmentRequestStatus.CLOSED,
      key: 'CLOSED',
    },
  ];

  const handleDropdown: MenuProps['onClick'] = ({ key }) => {
    if (filtersState?.status === key) return;
    handleFilterChange('status', key);
  };

  return (
    <div>
      <div className='flex items-center justify-between'>
        <Title level={5}>Подбор оборудования</Title>
        <div className='flex gap-4'>
          <Input
            className='w-[264px]'
            onChange={(e) => handleFilterChange('search', e.target.value)}
            placeholder='Название объекта'
          />

          <Button type='primary' onClick={() => navigate('/tools/new_request')}>
            <PlusOutlined /> Создать запрос
          </Button>
        </div>
      </div>
      <div className='pt-4 flex justify-between items-center'>
        <div className='flex gap-4'>
          <Text type='secondary'>Фильтры</Text>
          <Dropdown
            menu={{
              items,
              onClick: handleDropdown,
              selectable: true,
              multiple: true,
              selectedKeys: filtersState?.status ? [filtersState?.status] : [],
            }}
            trigger={['click']}
          >
            <Text className='cursor-pointer hover:text-sky-500'>
              Статус
              <Text type='secondary'>
                <DownOutlined style={{ fontSize: '11px', marginLeft: '5px' }} />
              </Text>
            </Text>
          </Dropdown>
          <Checkbox
            checked={filtersState?.is_urgent}
            onChange={(e) =>
              handleFilterChange('is_urgent', e?.target?.checked)
            }
          >
            Срочно
          </Checkbox>
          <Checkbox
            checked={filtersState?.has_hovs}
            onChange={(e) => handleFilterChange('has_hovs', e?.target?.checked)}
          >
            Есть ХОВС
          </Checkbox>
          <Checkbox
            checked={filtersState?.loyalty_program_participant}
            onChange={(e) =>
              handleFilterChange(
                'loyalty_program_participant',
                e?.target?.checked
              )
            }
          >
            Программа лояльности
          </Checkbox>
        </div>
        {checkFilters(filtersState) && (
          <Button type='link' className='p-0' onClick={clearFilters}>
            сбросить фильтры
          </Button>
        )}
      </div>
    </div>
  );
};

export const EquipmentRequestPage: React.FC = () => {
  const [filtersState, setFiltersState] = useState<Ifilters>({
    size: defaultPageSize,
    page: 1,
  });
  const [isModal, setIsModal] = useState<any>({});

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const equipmentRequestList = useAppSelector(
    equipmentRequestSelectors.equipmentRequestList
  );
  const data = equipmentRequestList?.items;
  const total = equipmentRequestList?.total;

  useEffect(() => {
    const payload: Ifilters = {
      size: filtersState?.size,
      page: filtersState?.page,
    };
    if (filtersState?.search) payload.search = filtersState?.search;
    if (filtersState?.updated_at__gte)
      payload.updated_at__gte = filtersState?.updated_at__gte;
    if (filtersState?.updated_at__lte)
      payload.updated_at__lte = filtersState?.updated_at__lte;
    if (filtersState?.status) payload.status = filtersState?.status;
    if (filtersState?.is_urgent) payload.is_urgent = filtersState?.is_urgent;
    if (filtersState?.has_hovs) payload.has_hovs = filtersState?.has_hovs;
    if (filtersState?.loyalty_program_participant)
      payload.loyalty_program_participant =
        filtersState?.loyalty_program_participant;
    if (filtersState?.order_by) payload.order_by = filtersState?.order_by;

    dispatch(equipmentRequestActions.fetchEquipmentRequestList(payload));
  }, [filtersState]);

  useEffect(() => {
    return () => {
      dispatch(equipmentRequestActions.setEquipmentRequestList(null));
    };
  }, []);

  const handleFilterChange = (
    key: string,
    value: number | string | boolean | null
  ) => {
    setFiltersState((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleCancel = () => {
    setIsModal({});
  };

  const handleRemove = (id: number) => {
    dispatch(
      equipmentRequestActions.deleteEquipmentRequest({ request_id: id })
    );
    setIsModal({});
  };

  const clearFilters = () => {
    const result = { ...filtersState };
    delete result.has_hovs;
    delete result.is_urgent;
    delete result.loyalty_program_participant;
    delete result.search;
    delete result.status;

    setFiltersState(result);
  };

  const columns: TableColumnsType<IEquipmentRequestItem> = [
    {
      title: 'ID Запроса',
      dataIndex: 'id',
    },
    {
      title: 'Наименование объекта и проекта',
      dataIndex: 'project_name',
      render: (text, record, index) => {
        return (
          <div className='flex justify-between'>
            <div className='flex flex-col'>
              {record?.loyalty_program_participant && (
                <Text type='success' style={{ fontSize: '10px' }}>
                  По программе лояльности
                </Text>
              )}
              <Link
                to={{
                  pathname:
                    record?.status === 'DRAFT'
                      ? `/tools/new_request/${record?.id}`
                      : `/tools/${record?.id}/docs`,
                }}
              >
                <div className='text-primary'>
                  Объект: {record.object_name}
                  <br />
                  Проект: {record.project_name}
                </div>
              </Link>
            </div>
            {record?.is_urgent && (
              <Tooltip title='Срочный запрос'>
                <ExclamationCircleOutlined className='ml-1 text-red-500' />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: 'Статус запроса',
      dataIndex: 'status',
      render: (status) => equipmentRequestStatus[status],
    },
    {
      title: 'Последнее изменение',
      dataIndex: 'updated_at',
      render: (text) => dateToSystemPage(text),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ХОВС',
      dataIndex: 'has_hovs',
      align: 'center',
      render: (text, record) =>
        record?.has_hovs ? (
          <CheckCircleOutlined className='text-green-500' />
        ) : (
          <CloseCircleOutlined className='text-gray-400' />
        ),
    },
    {
      title: '',
      dataIndex: '',
      render: (text, record) => (
        <div className='flex gap-2'>
          <Button
            disabled={record?.status === 'DRAFT'}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/tools/${record?.id}/chat`);
            }}
            icon={
              <Badge dot={record?.new_msg_count ? true : false}>
                <MessageOutlined />
              </Badge>
            }
          ></Button>

          <Button
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              setIsModal({
                show: true,
                title: `Вы уверены, что хотите удалить запрос?`,
                handleOk: () => handleRemove(Number(record.id)),
                okText: 'Да, удалить',
                okType: 'danger',
                body: (
                  <div>
                    <div>
                      <span className='font-semibold'>
                        Наименование проекта:{' '}
                      </span>
                      {record?.project_name}
                    </div>
                    <div>
                      <span className='font-semibold'>Название объекта: </span>
                      {record?.object_name}
                    </div>
                    {record?.object_address && (
                      <div>
                        <span className='font-semibold'>Адрес объекта: </span>
                        {record?.object_address}
                      </div>
                    )}
                  </div>
                ),
              });
            }}
          />
        </div>
      ),
    },
  ];

  if (!equipmentRequestList)
    return (
      <ContentWrapper>
        <div className='p-6'>Загрузка...</div>
      </ContentWrapper>
    );
  if (equipmentRequestList && !data?.length && !checkFilters(filtersState))
    return <ToolsPage />;
  if (equipmentRequestList)
    return (
      <div className='h-full p-6 pb-0 overflow-auto'>
        <div className='bg-white p-6'>
          <Table
            columns={columns}
            dataSource={data}
            rowKey='id'
            title={() => (
              <TableHeader
                handleFilterChange={handleFilterChange}
                filtersState={filtersState}
                clearFilters={clearFilters}
              />
            )}
            onChange={(pagination, filters, sorter: any) => {
              sorter?.field &&
                handleFilterChange(
                  'order_by',
                  `${sorter?.order === 'ascend' ? '' : '-'}${sorter?.field}`
                );

              pagination.current &&
                handleFilterChange('page', pagination.current);
            }}
            onRow={(record) => ({
              onClick: () => {
                record?.status === 'DRAFT'
                  ? navigate(`/tools/new_request/${record?.id}`)
                  : navigate(`/tools/${record?.id}/docs`);
              },
            })}
            pagination={{
              total: total ?? 0,
              showTotal: (total) => `${total} запросов`,
              defaultPageSize: defaultPageSize,
              defaultCurrent: 1,
              onShowSizeChange(_, size) {
                handleFilterChange('size', size);
              },
            }}
          />
        </div>

        {isModal?.show && (
          <Modal
            centered
            title={isModal?.title}
            open={isModal?.show}
            onOk={isModal?.handleOk}
            okText={isModal?.okText}
            okType={isModal?.okType}
            onCancel={handleCancel}
          >
            {isModal?.body}
          </Modal>
        )}
      </div>
    );
};
