import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/core/enums/loadingStatus';

export interface IEquipmentRequestAddPayload {
  project_id?: number;
  files?: FileList;
  loyalty_program_participant?: boolean;
  loyalty_program_number?: string;
  is_urgent?: boolean;
  object_address?: string;
  note?: string;
  status?: string;
  specified_project_name?: string;
  object_cipher?: string;
  customer?: string;
  general_designer?: string;
  construction_stage?: string;
  comment?: string | null;
  cloud_documents_url?: string;
}

export interface IEquipmentRequestFetchPayload {
  request_id: number;
  withoutFiles?: boolean;
}

export interface IEquipmentRequestUpdatePayload {
  request_id: number;
  is_urgent?: boolean;
  note?: string;
}

export interface IGetFilesPayload {
  request_id: number;
  version_id?: number;
}

export interface IGetMessagesListPayload {
  request_id: number;
  page: number | null;
  size: number | null;
}

export interface IEquipmentRequestItem {
  id?: number | null;
  status?: string | null;
  updated_at?: string | null;
  has_hovs?: boolean;
  is_urgent?: boolean;
  loyalty_program_participant?: boolean;
  object_name?: string | null;
  project_name?: string | null;
  new_msg_count: number;
  object_description?: string | null;
  loyalty_program_number?: string | null;
  object_cipher?: string;
  customer?: string;
  object_address?: string;
  specified_project_name?: string;
  general_designer?: string;
  construction_stage?: string;
  note?: string;
}

export interface IProjectItem {
  id: number;
  calc_name: string | null | undefined;
  object_name: string;
  object_address: string;
  organization_id: number | null | undefined;
  created_at: string;
  updated_at: string;
}

export interface IProjectListResponse {
  pages?: number | null | undefined;
  page: number | null;
  size: number | null;
  items: any;
  total: number | null;
  links: {
    first: string | null;
    last: string | null;
    self: string | null;
    next: string | null;
    prev: string | null;
  };
}

interface IUser {
  email: string;
  name: string;
  uuid: string;
}

export interface IMessagesListResponse {
  messages: {
    items: IMessageItem[];
    page: number | null;
    pages?: number | null | undefined;
    size: number | null;
    total: number | null;
  };

  users: IUser[];
}

export interface IProjectListFetchPayload {
  created_at__gte?: string;
  created_at__lte?: string;
  order_by?: string;
  search?: string;
  page?: number;
  size?: number;
  status?: string | null;
}

export interface IEquipmentRequestSendMessage {
  request_id: number;
  message_text: string;
}

export interface ISendMessageLock {
  status: LoadingStatus;
  response: any;
}

export interface IUpdateEquipmentRequestLock {
  status: LoadingStatus;
  response: any;
}
export interface ICreateEquipmentRequestLock {
  status: LoadingStatus;
  response: any;
}

export interface IControllerResponse {
  status: LoadingStatus;
  response: any;
}

export interface IMessageItem {
  id: number;
  sent_at: string;
  message_text: string;
  sender_id: string;
}

export interface IversionItem {
  id: number;
  created_at: string;
  updated_at: string;
}

export interface IversionsResponse {
  items: IversionItem[];
  total: number;
  page: number;
  size: number;
  pages: number;
  links: {
    first: string | null;
    last: string | null;
    self: string | null;
    next: string | null;
    prev: string | null;
  };
}

export interface IAddNewProject {
  object_name: string;
  object_address: string;
  calc_name: string;
}

export interface IGetFileUrl {
  file_id: number;
}

export interface IEquipmentRequestState {
  projectList: IProjectListResponse | null;
  equipmentRequestList: any | null;
  equipmentRequest: IEquipmentRequestItem | null;
  filesList: any | null;
  sendMessage?: IEquipmentRequestSendMessage;
  sendMessageLock: ISendMessageLock | null;
  messagesList: IMessagesListResponse | null;
  newMessagesList: IMessagesListResponse | null;
  versionsList: IversionsResponse | null;
  updateEquipmentRequestLock: IUpdateEquipmentRequestLock | null;
  createEquipmentRequestLock: ICreateEquipmentRequestLock | null;
  controllerResponse: IControllerResponse | null;
}

const initialState: IEquipmentRequestState = {
  projectList: null,
  equipmentRequestList: null,
  equipmentRequest: null,
  createEquipmentRequestLock: null,
  updateEquipmentRequestLock: null,
  controllerResponse: null,
  filesList: null,
  sendMessageLock: null,
  messagesList: null,
  newMessagesList: null,
  versionsList: null,
};

export const equipmentRequestSlice = createSlice({
  name: 'equipmentRequest',
  initialState,
  reducers: {
    fetchEquipmentRequestList: (
      state,
      action: PayloadAction<IProjectListFetchPayload | undefined | null>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    setEquipmentRequestList: (
      state,
      action: PayloadAction<IProjectListResponse | null>
    ) => ({
      ...state,
      equipmentRequestList: action.payload,
    }),

    fetchEquipmentRequest: (
      state,
      action: PayloadAction<IEquipmentRequestFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    setEquipmentRequest: (
      state,
      action: PayloadAction<IEquipmentRequestItem | null>
    ) => ({
      ...state,
      equipmentRequest: action.payload,
    }),

    addEquipmentRequest: (state, action: PayloadAction<any>) => ({
      ...state,
      payload: action.payload,
    }),

    setCreateEquipmentRequestLock: (
      state,
      action: PayloadAction<IUpdateEquipmentRequestLock | null>
    ) => ({
      ...state,
      createEquipmentRequestLock: action.payload,
    }),

    updateEquipmentRequest: (
      state,
      action: PayloadAction<IEquipmentRequestUpdatePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    getFiles: (state, action: PayloadAction<IGetFilesPayload>) => ({
      ...state,
      payload: action.payload,
    }),

    setFilesList: (state, action: PayloadAction<any | null>) => ({
      ...state,
      filesList: action.payload,
    }),

    deleteEquipmentRequest: (
      state,
      action: PayloadAction<IEquipmentRequestFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    fetchProjectList: (
      state,
      action: PayloadAction<IProjectListFetchPayload | undefined | null>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    setProjectList: (
      state,
      action: PayloadAction<IProjectListResponse | null>
    ) => ({
      ...state,
      projectList: action.payload,
    }),

    sendMessage: (
      state,
      action: PayloadAction<IEquipmentRequestSendMessage>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    setSendMessageLock: (state, action: PayloadAction<ISendMessageLock>) => ({
      ...state,
      sendMessageLock: action.payload,
    }),

    setUpdateEquipmentRequestLock: (
      state,
      action: PayloadAction<null | IUpdateEquipmentRequestLock>
    ) => ({
      ...state,
      updateEquipmentRequestLock: action.payload,
    }),

    setControllerResponse: (
      state,
      action: PayloadAction<null | IUpdateEquipmentRequestLock>
    ) => ({
      ...state,
      controllerResponse: action.payload,
    }),

    fetchMessagesList: (
      state,
      action: PayloadAction<IGetMessagesListPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    fetchNewMessagesList: (
      state,
      action: PayloadAction<IGetMessagesListPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    setMessagesList: (
      state,
      action: PayloadAction<IMessagesListResponse | null>
    ) => ({
      ...state,
      messagesList: action.payload,
    }),

    setNewMessagesList: (
      state,
      action: PayloadAction<IMessagesListResponse | null>
    ) => ({
      ...state,
      newMessagesList: action.payload,
    }),

    fetchVersionsList: (
      state,
      action: PayloadAction<IGetMessagesListPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    setVersionsList: (
      state,
      action: PayloadAction<IversionsResponse | null>
    ) => ({
      ...state,
      versionsList: action.payload,
    }),

    readMessages: (
      state,
      action: PayloadAction<IEquipmentRequestFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    fetchUpload: (state, action: PayloadAction<any>) => ({
      ...state,
      payload: action.payload,
    }),

    updateDraftEquipmentRequest: (state, action: PayloadAction<any>) => ({
      ...state,
      payload: action.payload,
    }),

    addNewProject: (state, action: PayloadAction<IAddNewProject>) => ({
      ...state,
      payload: action.payload,
    }),

    downloadFile: (state, action: PayloadAction<IGetFileUrl>) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const equipmentRequestReducer = equipmentRequestSlice.reducer;
export const equipmentRequestActions = equipmentRequestSlice.actions;
