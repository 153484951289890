import { airExchangeSagas } from '@/core/redux/slices/airExchange/sagas';
import { authSagas } from '@/core/redux/slices/auth/sagas';
import { productsSagas } from '@/core/redux/slices/products/sagas';
import { buildingObjectSagas } from '@/core/redux/slices/smokeExtraction/buildingObject/sagas';
import { calculationSagas } from '@/core/redux/slices/smokeExtraction/calculation/sagas';
import { systemCalculationsSagas } from '@/core/redux/slices/smokeExtraction/system/calculations/sagas';
import { systemParamsSagas } from '@/core/redux/slices/smokeExtraction/system/params/sagas';
import { reportSagas } from '@/core/redux/slices/smokeExtraction/system/report/sagas';
import { systemSagas } from '@/core/redux/slices/smokeExtraction/system/sagas';
import { usersSagas } from '@/core/redux/slices/users/sagas';
import { createRootSaga } from '@/core/redux/utils/sagas';

import { equipmentRequestSagas } from '../slices/equipment/sagas';

export const rootSaga = createRootSaga([
  ...authSagas,
  ...usersSagas,
  ...buildingObjectSagas,
  ...calculationSagas,
  ...systemParamsSagas,
  ...systemSagas,
  ...systemCalculationsSagas,
  ...reportSagas,
  ...airExchangeSagas,
  ...productsSagas,
  ...equipmentRequestSagas,
]);
