import { Navigate, Route, Routes } from 'react-router-dom';

import { CurrentRequestPage } from '@/views/tools/ToolsPage/CurrentRequestPage';

import ToolsAddRoute from './ToolsAddRoute';
import ToolsRoute from './ToolsRoute';

const ToolsRootRoute: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<ToolsRoute />} />
      <Route path='/new_request' element={<ToolsAddRoute />} />
      <Route path='/new_request/:id' element={<ToolsAddRoute />} />
      <Route path='/:id/:tab' element={<CurrentRequestPage />} />
      <Route path={'*'} element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default ToolsRootRoute;
