import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import type oas from '@/services/rest/base/openapi';

import {
  equipmentRequestActions,
  IAddNewProject,
  IEquipmentRequestAddPayload,
  IEquipmentRequestFetchPayload,
  IEquipmentRequestItem,
  IEquipmentRequestSendMessage,
  IGetFilesPayload,
  IProjectListResponse,
} from './slice';

type EquipmentRequestCreateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/equipment_request',
  'post'
>;

type EquipmentRequestFilesRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/equipment_request/{request_id}/files',
  'get'
>;

type EquipmentRequestDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/equipment_request/{request_id}',
  'delete'
>;

type EquipmentRequestSendMessageRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/equipment_request/{request_id}/chat',
  'post'
>;

type GetMessagesRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/equipment_request/{request_id}/chat',
  'get'
>;

type GetVersionsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/equipment_request/{request_id}/versions',
  'get'
>;

type EquipmentRequestUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/equipment_request/{request_id}',
  'patch'
>;

type ProjectListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/equipment_request/project',
  'get',
  '200'
>;

type ReadMessagesRequest = OASOutput<
  NormalizeOAS<typeof oas>,
  '/equipment_request/{request_id}/chat/read',
  'post',
  '200'
>;

type UploadRequest = OASOutput<
  NormalizeOAS<typeof oas>,
  '/equipment_request/{request_id}/files',
  'post',
  '200'
>;

type EquipmentRequestUpdateDraftRequest = OASOutput<
  NormalizeOAS<typeof oas>,
  '/equipment_request/{request_id}/draft',
  'patch',
  '200'
>;

function* fetchEquipmentRequestList(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(restCall, '/equipment_request', 'get', {
      query: {
        search: payload?.['search'],
        updated_at__gte: payload?.['updated_at__gte'],
        updated_at__lte: payload?.['updated_at__lte'],
        status: payload?.['status'],
        is_urgent: payload?.['is_urgent'],
        has_hovs: payload?.['has_hovs'],
        loyalty_program_participant: payload?.['loyalty_program_participant'],
        order_by: payload?.['order_by'],
        page: payload?.['page'],
        size: payload?.['size'],
      },
      ...authAdd(),
    });

    const projectList: IProjectListResponse = response;

    yield put(equipmentRequestActions.setEquipmentRequestList(projectList));
  } catch (error) {
    console.log('Error on project list fetching "equipment request"');
  }
}

function* fetchEquipmentRequest(
  action: PayloadAction<IEquipmentRequestFetchPayload>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    if (!payload?.withoutFiles) {
      yield put(
        equipmentRequestActions.getFiles({
          request_id: payload.request_id,
        })
      );
    }

    const response = yield call(
      restCall,
      '/equipment_request/{request_id}',
      'get',
      {
        params: {
          request_id: payload.request_id,
        },
        ...authAdd(),
      }
    );

    const equipmentRequest: IEquipmentRequestItem = response;

    yield put(equipmentRequestActions.setEquipmentRequest(equipmentRequest));
  } catch (error) {
    console.log('Error on fetching equipment request');
  }
}

function* fetchProjectList(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, ProjectListResponse> {
  const { payload } = action;

  try {
    const response = yield call(restCall, '/equipment_request/project', 'get', {
      query: {
        created_at__gte: payload?.['created_at__gte'],
        created_at__lte: payload?.['created_at__lte'],
        order_by: payload?.['order_by'],
        search: payload?.['search'],
        page: payload?.['page'],
        size: payload?.['size'],
      },
      ...authAdd(),
    });

    const projectList: IProjectListResponse = response;

    yield put(equipmentRequestActions.setProjectList(projectList));
  } catch (error) {
    console.log('Error on project list fetching "equipment request"');
  }
}

function* getFiles(
  action: PayloadAction<IGetFilesPayload>
): Generator<any, void, EquipmentRequestFilesRequest> {
  const { request_id, version_id } = action.payload;

  try {
    const response = yield call(
      restCall,
      `/equipment_request/{request_id}/files`,
      'get',
      {
        params: {
          request_id,
        },
        query: {
          version_id,
        },
        ...authAdd(),
      }
    );
    yield put(equipmentRequestActions.setFilesList(response));
  } catch (error) {
    console.log('Error on getting files "equipment request"', error);
  }
}

function* addEquipmentRequest(
  action: PayloadAction<IEquipmentRequestAddPayload>
): Generator<any, void, EquipmentRequestCreateRequest> {
  const { payload } = action;

  try {
    const response = yield call(restCall, '/equipment_request', 'post', {
      body: payload,
    });
    yield put(
      equipmentRequestActions.setCreateEquipmentRequestLock({
        status: LoadingStatus.LOADED,
        response: response,
      })
    );
  } catch (error) {
    console.log('Error on adding new equipment request', error);
    yield put(
      equipmentRequestActions.setCreateEquipmentRequestLock({
        status: LoadingStatus.ERROR,
        response: null,
      })
    );
  }
}

function* deleteEquipmentRequest(
  action: PayloadAction<IEquipmentRequestFetchPayload>
): Generator<any, void, any> {
  const { request_id } = action.payload;

  const request: EquipmentRequestDeleteRequest = {
    params: {
      request_id,
    },
    ...authAdd(),
  };

  try {
    yield call(restCall, '/equipment_request/{request_id}', 'delete', request);

    yield put(equipmentRequestActions.fetchEquipmentRequestList());
  } catch (error) {
    console.log('Error on delete equipment request', error);
  }
}

function* sendMessage(
  action: PayloadAction<IEquipmentRequestSendMessage>
): Generator<any, void, any> {
  const { request_id, message_text } = action.payload;

  const request: EquipmentRequestSendMessageRequest = {
    params: {
      request_id,
    },
    json: {
      message_text: message_text,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/equipment_request/{request_id}/chat',
      'post',
      request
    );

    yield put(
      equipmentRequestActions.setSendMessageLock({
        status: LoadingStatus.LOADED,
        response: response,
      })
    );
  } catch (error) {
    console.log('Error sending new chat message', error);
    yield put(
      equipmentRequestActions.setSendMessageLock({
        status: LoadingStatus.ERROR,
        response: null,
      })
    );
  }
}

function* fetchMessagesList(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, any> {
  const { request_id, page, size } = action.payload;

  const request: GetMessagesRequest = {
    params: {
      request_id,
    },
    query: {
      page: page,
      size: size,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/equipment_request/{request_id}/chat',
      'get',
      request
    );

    yield put(equipmentRequestActions.setMessagesList(response));
  } catch (error) {
    console.log('Error on fetching MessagesList "equipment request"');
  }
}

function* fetchNewMessagesList(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, any> {
  const { request_id, page, size } = action.payload;

  const request: GetMessagesRequest = {
    params: {
      request_id,
    },
    query: {
      page: page,
      size: size,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/equipment_request/{request_id}/chat',
      'get',
      request
    );

    yield put(equipmentRequestActions.setNewMessagesList(response));
    yield put(equipmentRequestActions.readMessages({ request_id: request_id }));
  } catch (error) {
    console.log('Error on fetchNewMessagesList "equipment request"');
  }
}

function* fetchVersionsList(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, any> {
  const { request_id, page, size } = action.payload;

  const request: GetVersionsRequest = {
    params: {
      request_id,
    },
    query: {
      page: page,
      size: size,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/equipment_request/{request_id}/versions',
      'get',
      request
    );

    yield put(equipmentRequestActions.setVersionsList(response));
  } catch (error) {
    console.log('Error on fetching VersionsList "equipment request"');
  }
}

function* updateEquipmentRequest(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, any> {
  const { request_id, is_urgent, note } = action.payload;

  const request: EquipmentRequestUpdateRequest = {
    params: {
      request_id,
    },
    json: {
      is_urgent: is_urgent,
      note: note,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/equipment_request/{request_id}',
      'PATCH',
      request
    );

    const equipmentRequest: IEquipmentRequestItem = response;
    yield put(equipmentRequestActions.setEquipmentRequest(equipmentRequest));

    yield put(
      equipmentRequestActions.setUpdateEquipmentRequestLock({
        status: LoadingStatus.LOADED,
        response: response,
      })
    );
  } catch (error) {
    console.log('Error on update equipment request');
    yield put(
      equipmentRequestActions.setUpdateEquipmentRequestLock({
        status: LoadingStatus.ERROR,
        response: null,
      })
    );
  }
}

function* readMessages(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, any> {
  const { request_id } = action.payload;

  const request: ReadMessagesRequest = {
    params: {
      request_id,
    },
    ...authAdd(),
  };

  try {
    yield call(
      restCall,
      '/equipment_request/{request_id}/chat/read',
      'post',
      request
    );
    yield put(
      equipmentRequestActions.fetchEquipmentRequest({
        request_id: Number(request_id),
        withoutFiles: true,
      })
    );
  } catch (error) {
    console.log('Error on fetching readMessages "equipment request"');
  }
}

function* fetchUpload(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, any> {
  const { request_id, payload, size, page } = action.payload;

  const request: UploadRequest = {
    params: {
      request_id,
    },
    body: payload,
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/equipment_request/{request_id}/files',
      'post',
      request
    );

    yield put(
      equipmentRequestActions.getFiles({
        request_id: request_id,
      })
    );
    yield put(
      equipmentRequestActions.fetchVersionsList({
        request_id: request_id,
        size,
        page,
      })
    );
    yield put(
      equipmentRequestActions.setUpdateEquipmentRequestLock({
        status: LoadingStatus.LOADED,
        response: response,
      })
    );
  } catch (error) {
    console.log('Error on fetching fetchUpload "equipment request"');
    yield put(
      equipmentRequestActions.setUpdateEquipmentRequestLock({
        status: LoadingStatus.ERROR,
        response: null,
      })
    );
  }
}

function* updateDraftEquipmentRequest(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, any> {
  const { request_id, payload } = action.payload;

  const request: EquipmentRequestUpdateDraftRequest = {
    params: {
      request_id,
    },
    body: payload,
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/equipment_request/{request_id}/draft',
      'PATCH',
      request
    );

    yield put(
      equipmentRequestActions.setCreateEquipmentRequestLock({
        status: LoadingStatus.LOADED,
        response: response,
      })
    );
  } catch (error) {
    console.log(
      'Error on fetching updateDraftEquipmentRequest "equipment request"'
    );
    yield put(
      equipmentRequestActions.setCreateEquipmentRequestLock({
        status: LoadingStatus.ERROR,
        response: null,
      })
    );
  }
}

function* addNewProject(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, IAddNewProject> {
  const payload = action.payload;

  const request = {
    json: payload,
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/equipment_request/project',
      'post',
      request
    );

    yield put(equipmentRequestActions.fetchProjectList({ size: 100, page: 1 }));

    yield put(
      equipmentRequestActions.setControllerResponse({
        status: LoadingStatus.LOADED,
        response: response,
      })
    );
  } catch (error) {
    console.log('Error on fetching addNewProject "equipment request"');
    yield put(
      equipmentRequestActions.setControllerResponse({
        status: LoadingStatus.ERROR,
        response: null,
      })
    );
  }
}

function* downloadFile(
  action: PayloadAction<any | undefined | null>
): Generator<any, void, any> {
  const { file_id } = action.payload;

  const request = {
    params: {
      file_id: file_id,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/equipment_request/file/{file_id}/download',
      'get',
      request
    );
    window.location.assign(response);
  } catch (error) {
    console.log('Error on fetching getFileUrl "equipment request"');
  }
}

export const equipmentRequestSagas = [
  takeLatest(
    equipmentRequestActions.fetchEquipmentRequestList,
    fetchEquipmentRequestList
  ),
  takeLatest(equipmentRequestActions.fetchProjectList, fetchProjectList),
  takeLatest(equipmentRequestActions.addEquipmentRequest, addEquipmentRequest),
  takeLatest(equipmentRequestActions.getFiles, getFiles),
  takeLatest(
    equipmentRequestActions.fetchEquipmentRequest,
    fetchEquipmentRequest
  ),
  takeLatest(
    equipmentRequestActions.deleteEquipmentRequest,
    deleteEquipmentRequest
  ),
  takeLatest(equipmentRequestActions.sendMessage, sendMessage),
  takeLatest(equipmentRequestActions.fetchMessagesList, fetchMessagesList),
  takeLatest(
    equipmentRequestActions.fetchNewMessagesList,
    fetchNewMessagesList
  ),
  takeLatest(equipmentRequestActions.fetchVersionsList, fetchVersionsList),
  takeLatest(
    equipmentRequestActions.updateEquipmentRequest,
    updateEquipmentRequest
  ),
  takeLatest(equipmentRequestActions.readMessages, readMessages),
  takeLatest(equipmentRequestActions.fetchUpload, fetchUpload),
  takeLatest(
    equipmentRequestActions.updateDraftEquipmentRequest,
    updateDraftEquipmentRequest
  ),
  takeLatest(equipmentRequestActions.addNewProject, addNewProject),
  takeLatest(equipmentRequestActions.downloadFile, downloadFile),
];
