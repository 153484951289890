import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.equipmentRequest;

//equipmentRequest
const projectList = createSelector([selector], (state) => state?.projectList);

const equipmentRequestList = createSelector(
  [selector],
  (state) => state?.equipmentRequestList
);
const filesList = createSelector([selector], (state) => state?.filesList);

const equipmentRequest = createSelector(
  [selector],
  (state) => state?.equipmentRequest
);

const sendMessageLock = createSelector(
  [selector],
  (state) => state?.sendMessageLock
);

const updateEquipmentRequestLock = createSelector(
  [selector],
  (state) => state?.updateEquipmentRequestLock
);

const createEquipmentRequestLock = createSelector(
  [selector],
  (state) => state?.createEquipmentRequestLock
);

const controllerResponse = createSelector(
  [selector],
  (state) => state?.controllerResponse
);

const messagesList = createSelector([selector], (state) => state?.messagesList);

const newMessagesList = createSelector(
  [selector],
  (state) => state?.newMessagesList
);

const versionsList = createSelector([selector], (state) => state?.versionsList);

export const equipmentRequestSelectors = {
  projectList,
  equipmentRequestList,
  equipmentRequest,
  filesList,
  sendMessageLock,
  messagesList,
  newMessagesList,
  versionsList,
  updateEquipmentRequestLock,
  createEquipmentRequestLock,
  controllerResponse,
};
