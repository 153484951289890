import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

const { Text, Title } = Typography;

export const ToolsPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='h-full p-6 pb-0 overflow-auto'>
      <div className='bg-white p-6 h-full flex text-center items-center justify-center'>
        <div className='max-w-[800px] flex flex-col gap-2.5 items-center'>
          <Title level={5}>
            Создайте свой первый запрос на подбор оборудования
          </Title>
          <Text>
            Добро пожаловать в единую платформу для управления запросами на
            подбор оборудования. Модуль централизует всю коммуникацию и
            документы по проекту, обеспечивая удобное отслеживание статусов,
            ведение чата со специалистами, оперативное внесение корректировок в
            запрос и получение итоговых технико-коммерческих предложений. Это
            решение значительно удобнее и эффективнее по сравнению с привычными
            email-переписками, позволяя работать быстрее <br />и подбирать любое
            ОВиК оборудование.
          </Text>

          <Button
            className='mt-1'
            type='primary'
            onClick={() => navigate('/tools/new_request')}
            icon={<PlusOutlined />}
          >
            Создать запрос
          </Button>
        </div>
      </div>
    </div>
  );
};
