import {
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileZipOutlined,
} from '@ant-design/icons';

export const getIcon = (fileName: string) => {
  if (!fileName) return '';
  const fileExtension = fileName?.split('.')?.slice(-1)?.[0];

  if (['doc', 'docx']?.includes(fileExtension))
    return <FileWordOutlined style={{ fontSize: '22px', color: '#1890ff' }} />;
  if (['pdf']?.includes(fileExtension))
    return <FilePdfOutlined style={{ fontSize: '22px', color: '#1890ff' }} />;
  if (['zip', 'rar']?.includes(fileExtension))
    return <FileZipOutlined style={{ fontSize: '22px', color: '#1890ff' }} />;
  if (['xlsx', 'xls']?.includes(fileExtension))
    return <FileExcelOutlined style={{ fontSize: '22px', color: '#1890ff' }} />;
  if (['jpg', 'png', 'jpeg']?.includes(fileExtension))
    return <FileImageOutlined style={{ fontSize: '22px', color: '#1890ff' }} />;
  return <FileOutlined />;
};

export const successMessage = (messageApi: any, text: string) => {
  messageApi.open({
    type: 'success',
    content: text,
  });
  return true;
};

export const errorMessage = (messageApi: any, text: string) => {
  messageApi.open({
    type: 'error',
    content: text,
  });
};
