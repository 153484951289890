import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, message, Modal } from 'antd';
import * as yup from 'yup';

import { InputForm } from '@/components/form/Input';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { equipmentRequestSelectors } from '@/core/redux/slices/equipment/selectors';
import {
  equipmentRequestActions,
  IAddNewProject,
} from '@/core/redux/slices/equipment/slice';

import { errorMessage, successMessage } from './helper';

interface Imodal {
  open: boolean;
  handleCancel: any;
  setProjectId: any;
}

const schema = yup.object().shape({
  object_name: yup.string().required('Это поле обязательно для заполнения'),
  object_address: yup.string().required('Это поле обязательно для заполнения'),
  calc_name: yup.string().required('Это поле обязательно для заполнения'),
});

export const ModalNewProject: React.FC<Imodal> = ({
  open,
  handleCancel,
  setProjectId,
}) => {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const methods = useForm<IAddNewProject>({
    resolver: yupResolver(schema),
  });
  const controllerResponse = useAppSelector(
    equipmentRequestSelectors.controllerResponse
  );

  const submit = () => {
    const methodsData = methods.getValues();
    dispatch(equipmentRequestActions.addNewProject(methodsData));
  };

  useEffect(() => {
    if (controllerResponse?.status === LoadingStatus.LOADED) {
      setProjectId(controllerResponse?.response?.id);
      dispatch(equipmentRequestActions.setControllerResponse(null));
      successMessage(messageApi, 'Данные успешно сохранены');
      handleCancel();
    } else if (controllerResponse?.status === LoadingStatus.ERROR) {
      dispatch(equipmentRequestActions.setControllerResponse(null));
      errorMessage(
        messageApi,
        'Не удалось сохранить данные, что-то пошло не так'
      );
    }
  }, [controllerResponse]);

  const ModalFooter: React.FC = () => {
    return (
      <div className='flex gap-2'>
        <Button className='w-1/2' onClick={handleCancel}>
          Отменить
        </Button>

        <Button
          className='w-1/2'
          type='primary'
          disabled={!methods.formState.isValid}
          onClick={submit}
        >
          Создать проект
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title='Создание нового проекта'
      open={open}
      onCancel={handleCancel}
      footer={null}
    >
      {contextHolder}
      <div className='my-6'>
        <FormProvider {...methods}>
          <form>
            <div className='flex flex-col gap-4'>
              <InputForm
                name='object_name'
                label='Название объекта'
                placeholder='Введите название объекта'
              />
              <InputForm
                name='object_address'
                label='Адрес объекта'
                placeholder='Введите адрес'
              />
              <InputForm
                name='calc_name'
                label='Название проекта'
                placeholder='Введите название проекта'
              />
            </div>
          </form>
        </FormProvider>
      </div>
      <ModalFooter />
    </Modal>
  );
};
