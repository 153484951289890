import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from 'antd';
import { includes, uniqBy } from 'lodash';
import { object, string } from 'yup';

import { InputForm } from '@/components/form/Input';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { equipmentRequestSelectors } from '@/core/redux/slices/equipment/selectors';
import {
  equipmentRequestActions,
  IEquipmentRequestItem,
} from '@/core/redux/slices/equipment/slice';
import { usersSelectors } from '@/core/redux/slices/users/selectors';
import { dateToSystemPage } from '@/core/utils/dateUtils';
const { Text } = Typography;

interface IChatTab {
  data: IEquipmentRequestItem | null;
  tab?: string;
}

const defaultPageSize = 20;

const schema = object().shape({
  message_text: string(),
});

export const ChatTab: React.FC<IChatTab> = ({ data, tab }) => {
  const [messages, setMessages] = useState<any>([]);
  const chatContainerRef = useRef(null);
  const currentPage = useRef(1);
  const maxPages = useRef(1);
  const intRef = useRef<any>(null);

  const dispatch = useAppDispatch();
  const sendMessageLock = useAppSelector(
    equipmentRequestSelectors.sendMessageLock
  );
  const messagesList: any = useAppSelector(
    equipmentRequestSelectors.messagesList
  );
  const newMessagesList: any = useAppSelector(
    equipmentRequestSelectors.newMessagesList
  );

  const newMessages = newMessagesList?.messages?.items?.filter(
    (el: any) =>
      !includes(
        messages?.map((e: any) => e?.id),
        el?.id
      )
  );

  const equipmentRequest = useAppSelector(
    equipmentRequestSelectors.equipmentRequest
  );

  const checkNewMessages = Number(equipmentRequest?.new_msg_count);

  const user = useAppSelector(usersSelectors.user);

  const methods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      message_text: '',
    },
  });

  useEffect(() => {
    if (checkNewMessages) {
      dispatch(
        equipmentRequestActions.fetchNewMessagesList({
          request_id: Number(data?.id),
          size: defaultPageSize,
          page: 1,
        })
      );
    }
  }, [checkNewMessages]);

  useEffect(() => {
    if (!intRef.current) {
      const newInterval = setInterval(() => {
        dispatch(
          equipmentRequestActions.fetchEquipmentRequest({
            request_id: Number(data?.id),
            withoutFiles: true,
          })
        );
      }, 5000);
      intRef.current = newInterval;
    }

    if (tab !== 'chat') {
      clearInterval(intRef.current);
      intRef.current = null;
    }

    return () => clearInterval(intRef.current);
  }, [tab]);

  useEffect(() => {
    fetchMessages();

    data?.new_msg_count &&
      dispatch(
        equipmentRequestActions.readMessages({ request_id: Number(data?.id) })
      );

    return () => {
      dispatch(equipmentRequestActions.setMessagesList(null));
      setMessages([]);
      methods.reset({ message_text: '' });
    };
  }, []);

  const fetchMessages = () => {
    if (currentPage?.current > maxPages?.current) {
      return;
    } else {
      dispatch(
        equipmentRequestActions.fetchMessagesList({
          request_id: Number(data?.id),
          size: defaultPageSize,
          page: currentPage.current,
        })
      );
    }
  };

  useEffect(() => {
    if (sendMessageLock?.status === LoadingStatus.LOADED) {
      methods.reset({ message_text: '' });
      setMessages([]);
      currentPage.current = 1;
      dispatch(
        equipmentRequestActions.fetchMessagesList({
          request_id: Number(data?.id),
          size: defaultPageSize,
          page: 1,
        })
      );
    }
  }, [sendMessageLock]);

  useEffect(() => {
    if (messagesList?.messages?.items?.length) {
      setMessages(
        uniqBy([...messages, ...messagesList?.messages?.items], 'id')
      );
    }
    currentPage.current = messagesList?.messages?.page + 1;
    maxPages.current = messagesList?.messages?.pages;
  }, [messagesList]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const container: any = chatContainerRef.current;
      const { scrollTop, clientHeight, scrollHeight } = container;

      if (Math.ceil(Math.abs(scrollTop) + clientHeight) >= scrollHeight) {
        fetchMessages();
      }
    }
  };

  useEffect(() => {
    const container: any = chatContainerRef.current;
    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sendMessage = () => {
    const methodsData = methods.getValues();

    methodsData?.message_text &&
      dispatch(
        equipmentRequestActions.sendMessage({
          request_id: Number(data?.id),
          message_text: methodsData?.message_text,
        })
      );
  };

  const getName = (value: string) => {
    if (value === null) return 'Аэропро.Эксперт';
    return messagesList?.users?.find((el: any) => el.uuid === value)?.name;
  };

  return (
    <div className='flex flex-col min-h-96 justify-end '>
      <div
        ref={chatContainerRef}
        className='flex flex-col-reverse max-h-[calc(100vh_-_355px)] min-h-[calc(100vh_-_355px)] overflow-auto pr-2'
      >
        {newMessages?.map((data: any, ind: number) => (
          <div
            key={data?.id}
            className={`bg-blue-50 rounded-lg p-4 my-5 w-fit max-w-[80%] ${data?.sender_id === user?.uuid ? 'self-end' : ''}`}
          >
            <div className='flex justify-between'>
              <Text type='secondary' className='mr-5'>
                {getName(data?.sender_id)}
              </Text>
              <Text className='text-slate-300 ml-5'>
                {dateToSystemPage(data?.sent_at)}
              </Text>
            </div>
            <div>{data?.message_text || ''}</div>
          </div>
        ))}
        {newMessages?.length > 0 && (
          <div className='flex justify-center'>
            <Text>
              {newMessages?.length === 1
                ? ' Новое сообщение '
                : ' Новые сообщения '}
            </Text>
          </div>
        )}

        {messages?.map((data: any, ind: number) => (
          <div
            key={data?.id}
            className={`bg-blue-50 rounded-lg p-4 my-5 w-fit max-w-[80%] ${data?.sender_id === user?.uuid ? 'self-end' : ''}`}
          >
            <div className='flex justify-between'>
              <Text type='secondary' className='mr-5'>
                {getName(data?.sender_id)}
              </Text>
              <Text className='text-slate-300 ml-5'>
                {dateToSystemPage(data?.sent_at)}
              </Text>
            </div>
            <div>{data?.message_text || ''}</div>
          </div>
        ))}
      </div>

      <FormProvider {...methods}>
        <div className='flex gap-4 pt-4'>
          <InputForm
            name='message_text'
            placeholder='Напишите сообщение здесь'
            onKeyUp={(e) => e?.key === 'Enter' && sendMessage()}
          />
          <Button
            disabled={!methods.formState.isDirty}
            type='primary'
            onClick={sendMessage}
          >
            Отправить
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};
