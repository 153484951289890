import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Form, message, Spin, Typography } from 'antd';
import { array, boolean, mixed, number, object, string } from 'yup';

import { FilesForm } from '@/components/form/Files';
import { InputForm } from '@/components/form/Input';
import { RadioGroupForm } from '@/components/form/Radio';
import { SelectForm } from '@/components/form/Select';
import { TextAreaForm } from '@/components/form/TextArea';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { equipmentRequestSelectors } from '@/core/redux/slices/equipment/selectors';
import { equipmentRequestActions } from '@/core/redux/slices/equipment/slice';

import { errorMessage, getIcon, successMessage } from './helper';
import { ModalNewProject } from './ModalNewProject';

const { Text, Title, Paragraph } = Typography;

export const CreatorOfRequest: React.FC = () => {
  const [loyalty, setLoyalty] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [file_ids, setFile_ids] = useState<number[]>([]);
  const [isSending, setSending] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();

  const projectList = useAppSelector(equipmentRequestSelectors.projectList);
  const equipmentRequest: any = useAppSelector(
    equipmentRequestSelectors.equipmentRequest
  );
  const createEquipmentRequestLock = useAppSelector(
    equipmentRequestSelectors.createEquipmentRequestLock
  );

  const filesList = useAppSelector(equipmentRequestSelectors.filesList);

  const selectOptions = [{ value: 0, label: 'Создать новый' }];

  projectList?.items?.forEach((el: any) => {
    selectOptions.unshift({ value: el?.id, label: el?.calc_name });
  });

  const schema = object({
    project_id: number().required(),
    loyalty_program_participant: boolean().required(),
    is_urgent: boolean().default(false).required(),
    comment: string(),
    files: array()
      .of(
        mixed().test(
          'fileSize',
          'Размер файла слишком велик, максимум 30 МБ',
          (value: any) => {
            if (!value) return true;
            return value?.originFileObj?.size <= 1024 * 1024 * 30; // Максимальный размер файла 30МБ
          }
        )
      )
      .test(
        'totalFilesSize',
        'Общий размер файлов слишком велик, максимум 100 МБ',
        function (value) {
          if (!value) return true;
          const totalSize = value.reduce(
            (acc, file: any) => acc + file?.originFileObj?.size,
            0
          );
          const maxTotalSize = 1024 * 1024 * 100; // Общий максимальный размер файлов 100МБ
          return Number(totalSize) <= maxTotalSize;
        }
      ),
  });

  const methods = useForm<any>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      loyalty_program_participant: false,
      is_urgent: false,
      comment: '',
      files: [],
    },
  });

  useEffect(() => {
    if (createEquipmentRequestLock?.status === LoadingStatus.LOADED) {
      dispatch(equipmentRequestActions.setCreateEquipmentRequestLock(null));
      successMessage(messageApi, 'Данные успешно сохранены');
      setTimeout(() => navigate('/tools'), 1500);
    } else if (createEquipmentRequestLock?.status === LoadingStatus.ERROR) {
      dispatch(equipmentRequestActions.setCreateEquipmentRequestLock(null));
      errorMessage(
        messageApi,
        'Не удалось сохранить данные, что-то пошло не так'
      );
      setSending('');
    }
  }, [createEquipmentRequestLock]);

  useEffect(() => {
    if (equipmentRequest) {
      const resultData: any = {};
      Object?.keys(equipmentRequest)?.forEach((key: any) => {
        if (
          ![
            'id',
            'new_msg_count',
            'has_hovs',
            'object_name',
            'project_name',
            'updated_at',
            'status',
          ]?.includes(key) &&
          equipmentRequest[key] !== null
        )
          resultData[key] = equipmentRequest?.[key];
      });

      methods.reset(resultData);
    }
    const newFiles = filesList?.in_files?.map((el: any) => el?.id);
    methods.setValue('file_ids', newFiles ?? null);
    setFile_ids(newFiles);
  }, [equipmentRequest, filesList, projectList]);

  useEffect(() => {
    if (id) {
      dispatch(
        equipmentRequestActions.fetchEquipmentRequest({
          request_id: Number(id),
        })
      );
    }

    dispatch(
      equipmentRequestActions.fetchProjectList({
        size: 100,
        page: 1,
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch(equipmentRequestActions.setEquipmentRequest(null));
      dispatch(equipmentRequestActions.setProjectList(null));
      dispatch(equipmentRequestActions.setFilesList(null));
    };
  }, []);

  const urgentOptions = [
    { label: 'Срочно', value: true },
    { label: 'В обычном режиме', value: false },
  ];

  const loyaltyOptions = [
    { label: 'Участвовать', value: true },
    { label: 'Пропустить', value: false },
  ];

  const handleCreate = (status: string) => {
    setSending(status);
    const methodsData = methods.getValues();

    const data = new FormData();
    Object.keys(methodsData).forEach((key: string) => {
      if (!['files', 'file_ids'].includes(key)) {
        ![null, undefined, ''].includes(methodsData[key]) &&
          data.append(key, methodsData[key]);
      }
    });

    data.append('status', status);

    methodsData?.file_ids?.length &&
      methodsData?.file_ids.forEach((id: any) => {
        data.append('file_ids', id);
      });

    methodsData?.files?.length &&
      methodsData?.files.forEach((file: any) => {
        data.append('files', file.originFileObj);
      });

    if (id) {
      dispatch(
        equipmentRequestActions.updateDraftEquipmentRequest({
          request_id: id,
          payload: data,
        })
      );
    } else {
      dispatch(equipmentRequestActions.addEquipmentRequest(data));
    }
  };

  const handleSelectChange = (value: any) => {
    value !== 0 ? methods.setValue('project_id', value) : setOpenModal(true);
  };

  const handleDeleteFile = (file_id: number) => {
    const files = file_ids?.filter((el: number) => el !== file_id);

    methods.setValue('file_ids', files);
    setFile_ids(files);
  };

  const downloadFile = (id: number) => {
    dispatch(
      equipmentRequestActions.downloadFile({
        file_id: id,
      })
    );
  };

  const getSelectLabel = (id: number) => {
    const result = projectList?.items?.find((el: any) => el?.id === id);

    return (
      <div>
        <div> Объект: {result?.object_name}</div>
        <div>Проект: {result?.calc_name}</div>
      </div>
    );
  };

  return (
    <div className='h-full p-6 pb-0 overflow-auto'>
      {contextHolder}
      <div className='bg-white p-6'>
        <div className='flex gap-4 items-center'>
          <Button icon={<LeftOutlined />} onClick={() => navigate(`/tools`)} />
          <Title level={5} className='!mb-0'>
            Новый запрос
          </Title>
        </div>
        <FormProvider {...methods}>
          <Form>
            <div className='pt-8 pb-4 grid grid-cols-1 gap-4  items-end w-3/5'>
              <SelectForm
                placeholder='Выбрать проект'
                size='large'
                options={selectOptions}
                optionRender={({ data }) => {
                  if (data.value !== 0) {
                    return <>{getSelectLabel(Number(data?.value))}</>;
                  } else {
                    return (
                      <div className='text-primary'>
                        <PlusOutlined /> {data?.label}
                      </div>
                    );
                  }
                }}
                name={`project_id`}
                onChange={handleSelectChange}
              />

              <TextAreaForm
                className='mb-4'
                rows={4}
                name='object_description'
                label='Описание объекта'
              />

              <Title level={5} className='mb-4'>
                Срочность выполнения
              </Title>

              <RadioGroupForm
                name='is_urgent'
                defaultValue={'false'}
                className='font-normal'
                options={urgentOptions}
                onChange={(event) =>
                  methods.setValue('is_urgent', event?.target?.value)
                }
              />

              <TextAreaForm
                rows={4}
                name='note'
                label='Заметка к запросу'
                placeholder='Вы можете добавить заметку, она будет видна только вам'
              />
            </div>

            <Divider className='m-0'></Divider>

            <div className='pt-4 pb-4 grid grid-cols-1 gap-4  items-end w-3/5'>
              <Title level={5} className='mb-4'>
                Программа лояльности
              </Title>
              <RadioGroupForm
                name='loyalty_program_participant'
                defaultValue={'false'}
                className='font-normal'
                options={loyaltyOptions}
                onChange={() => setLoyalty(!loyalty)}
              />

              {loyalty && (
                <>
                  <InputForm
                    name='loyalty_program_number'
                    label='Номер участника программы'
                    placeholder='Введите значение'
                  />
                  <InputForm
                    name='object_address'
                    label='Точный адрес объекта'
                    placeholder='Введите значение'
                  />
                  <InputForm
                    name='specified_project_name'
                    label='Уточненное название'
                    placeholder='Введите значение'
                  />
                  <InputForm
                    name='object_cipher'
                    label='Шифр объекта'
                    placeholder='Введите значение'
                  />
                  <InputForm
                    name='customer'
                    label='Заказчик'
                    placeholder='Введите значение'
                  />
                  <InputForm
                    name='general_designer'
                    label='Генпроектировщик'
                    placeholder='Введите значение'
                  />
                  <InputForm
                    name='construction_stage'
                    label='Стадия строительства'
                    placeholder='Введите значение'
                  />
                </>
              )}
            </div>

            <Divider className='m-0'></Divider>

            <div className='pt-4 pb-4 grid grid-cols-1 gap-4  items-end w-3/5'>
              <Title level={5} className='mb-4'>
                Подробная информация
              </Title>
              <Paragraph type='secondary'>
                Вы можете{' '}
                <Button
                  type='link'
                  htmlType='button'
                  className='p-0 underline h-fit'
                  onClick={() =>
                    window.location.assign(
                      'https://storage.yandexcloud.net/aeropro-static/hovs_template.xlsx'
                    )
                  }
                >
                  скачать шаблон Excel
                </Button>{' '}
                для ввода данных по системам ХОВС. <br />
                Работайте в привычной среде: заполните файл, добавьте
                необходимые данные или скопируйте их из других документов. Когда
                все будет готово, загрузите файл через поле загрузки, и мы
                быстро обработаем его для дальнейшей работы.
              </Paragraph>

              <TextAreaForm
                rows={4}
                defaultValue=''
                name='comment'
                label='Комментарий к запросу'
                placeholder='Укажите пожелания или требования к подбору, а также предпочтительного производителя или марку оборудования. Если производитель не указан, мы подберем и предложим оборудование, максимально соответствующее требованиям проекта.'
              />

              {filesList?.in_files?.length > 0 && (
                <div className='flex flex-col gap-2'>
                  <Text type='secondary'>Ранее добавленные файлы</Text>
                  {filesList?.in_files?.map((elem: any) => {
                    if (file_ids?.includes(elem?.id))
                      return (
                        <div key={elem?.id} className='flex justify-between '>
                          <div className='flex flex-row gap-4'>
                            {getIcon(elem?.file_name)}
                            <Button
                              className='p-0 h-fit'
                              type='link'
                              onClick={() => downloadFile(elem?.id)}
                            >
                              {elem?.file_name}
                            </Button>
                          </div>
                          <Button
                            type='link'
                            htmlType='button'
                            className='p-0 underline underline-offset-4 h-fit'
                            onClick={() => handleDeleteFile(elem?.id)}
                          >
                            Удалить
                          </Button>
                        </div>
                      );
                  })}
                </div>
              )}

              <FilesForm name={'files'} />

              <InputForm
                name='cloud_documents_url'
                label='Или добавьте ссылку на документ'
                placeholder='Введите значение'
              />
            </div>
            <Divider className='m-0'></Divider>
            <div className='pt-4 flex gap-2'>
              <Button
                disabled={
                  !methods.formState.isValid ||
                  ['NEW', 'DRAFT']?.includes(isSending)
                }
                type='primary'
                htmlType='submit'
                onClick={() => handleCreate('NEW')}
              >
                {isSending === 'NEW' && (
                  <Spin indicator={<LoadingOutlined spin />} size='small' />
                )}{' '}
                Отправить запрос
              </Button>
              <Button
                disabled={
                  !methods.formState.isValid ||
                  ['NEW', 'DRAFT']?.includes(isSending)
                }
                onClick={() => handleCreate('DRAFT')}
              >
                {isSending === 'DRAFT' && (
                  <Spin indicator={<LoadingOutlined spin />} size='small' />
                )}{' '}
                Сохранить как черновик
              </Button>
            </div>
          </Form>
        </FormProvider>
      </div>
      {openModal && (
        <ModalNewProject
          open={openModal}
          handleCancel={() => setOpenModal(false)}
          setProjectId={(e: number) => methods.setValue('project_id', e)}
        />
      )}
    </div>
  );
};
