import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, message, Select, Spin, Typography } from 'antd';
import { array, mixed, number, object, string } from 'yup';

import { FilesForm } from '@/components/form/Files';
import { InputForm } from '@/components/form/Input';
import { TextAreaForm } from '@/components/form/TextArea';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { equipmentRequestSelectors } from '@/core/redux/slices/equipment/selectors';
import {
  equipmentRequestActions,
  IEquipmentRequestItem,
} from '@/core/redux/slices/equipment/slice';
import { formatToDate } from '@/core/utils/dateUtils';

import { errorMessage, getIcon, successMessage } from '../helper';

const { Text } = Typography;

interface IDocsTab {
  data: IEquipmentRequestItem | null;
}

const defaultPageSize = 100;

export const DocsTab: React.FC<IDocsTab> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [file_ids, setFile_ids] = useState<number[]>([]);
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [isSending, setSending] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const versionFilesList = useAppSelector(equipmentRequestSelectors.filesList);
  const versionsList = useAppSelector(equipmentRequestSelectors.versionsList);
  const updateEquipmentRequestLock = useAppSelector(
    equipmentRequestSelectors.updateEquipmentRequestLock
  );

  const [messageApi, contextHolder] = message.useMessage();

  const arrFile_ids = versionFilesList?.in_files?.map((el: any) => el?.id);

  useEffect(() => {
    if (updateEquipmentRequestLock?.status === LoadingStatus.LOADED) {
      dispatch(equipmentRequestActions.setUpdateEquipmentRequestLock(null));
      successMessage(messageApi, 'Данные успешно отправлены');
      setTimeout(() => navigate('/tools'), 1500);
    } else if (updateEquipmentRequestLock?.status === LoadingStatus.ERROR) {
      dispatch(equipmentRequestActions.setUpdateEquipmentRequestLock(null));
      errorMessage(
        messageApi,
        'Не удалось сохранить данные, что-то пошло не так'
      );
      setSending(false);
    }
  }, [updateEquipmentRequestLock]);

  const optionsVersions = versionsList?.items?.map((el: any, ind: number) => {
    const count = versionsList?.items?.length - ind;
    return {
      value: el?.id,
      label:
        ind === 0 ? (
          <div>
            Актуальные файлы запроса{' '}
            <Text type='secondary'>{formatToDate(el?.created_at)}</Text>
          </div>
        ) : (
          <div>
            {count} версия{' '}
            <Text type='secondary'>{formatToDate(el?.created_at)}</Text>
          </div>
        ),
    };
  });

  const schema = object({
    cloud_documents_url: string(),
    comment: string(),
    files: array()
      .of(
        mixed().test(
          'fileSize',
          'Размер файла слишком велик, максимум 30 МБ',
          (value: any) => {
            if (!value) return true;
            return value?.originFileObj?.size <= 1024 * 1024 * 30; // Максимальный размер файла 30МБ;
          }
        )
      )
      .test(
        'totalFilesSize',
        'Общий размер файлов слишком велик, максимум 100 МБ',
        function (value) {
          if (!value) return true;
          const totalSize = value.reduce(
            (acc, file: any) => acc + file?.originFileObj?.size,
            0
          );
          const maxTotalSize = 1024 * 1024 * 100; // Общий максимальный размер файлов 100МБ
          return Number(totalSize) <= maxTotalSize;
        }
      ),
    file_ids: array()
      .of(number())
      .nullable()
      .default(() => arrFile_ids),
  });

  const methods = useForm<any>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      cloud_documents_url: '',
      comment: '',
      files: [],
      file_ids: arrFile_ids,
    },
  });

  // Отслеживаем изменения в форме
  methods.watch(
    ({ cloud_documents_url, comment, file_ids, files }, test: any) => {
      if (
        cloud_documents_url ||
        comment ||
        files?.length ||
        file_ids?.length !== arrFile_ids?.length
      ) {
        setButtonActive(true);
      } else {
        setButtonActive(false);
      }
    }
  );

  useEffect(() => {
    const newFiles = versionFilesList?.in_files?.map((el: any) => el?.id);

    methods.setValue('file_ids', newFiles);
    setFile_ids(newFiles);
  }, [versionFilesList]);

  useEffect(() => {
    dispatch(
      equipmentRequestActions.fetchVersionsList({
        request_id: Number(data?.id),
        size: defaultPageSize,
        page: currentPage,
      })
    );

    return () => {
      dispatch(equipmentRequestActions.setFilesList(null));
      dispatch(equipmentRequestActions.setVersionsList(null));
    };
  }, []);

  const handleDeleteFile = (file_id: number) => {
    const files = file_ids?.filter((el: number) => el !== file_id);

    methods.setValue('file_ids', files);
    setFile_ids(files);
  };

  const handleSelectChange = (value: number) => {
    if (versionFilesList?.id === value) return;
    else
      dispatch(
        equipmentRequestActions.getFiles({
          request_id: Number(data?.id),
          version_id: value,
        })
      );
  };

  const downloadFile = (id: number) => {
    dispatch(
      equipmentRequestActions.downloadFile({
        file_id: id,
      })
    );
  };

  const submit = () => {
    setSending(true);
    const methodsData = methods.getValues();

    const newData = new FormData();
    Object.keys(methodsData).forEach((key: any) => {
      if (!['files', 'file_ids'].includes(key)) {
        methodsData[key] && newData.append(key, methodsData[key]);
      }
    });

    methodsData?.file_ids?.length &&
      methodsData?.file_ids.forEach((id: any) => {
        newData.append('file_ids', id);
      });

    methodsData?.files?.length &&
      methodsData?.files.forEach((file: any) => {
        newData.append('files', file.originFileObj);
      });

    dispatch(
      equipmentRequestActions.fetchUpload({
        request_id: data?.id,
        payload: newData,
        size: defaultPageSize,
        page: currentPage,
      })
    );
  };

  return (
    <div className='pt-4 pb-4 grid grid-cols-1 gap-4 items-end max-h-[calc(100vh_-_305px)] overflow-auto'>
      {contextHolder}
      <FormProvider {...methods}>
        <div className='flex flex-col gap-4 w-3/5'>
          <Select
            //   className='w-[170px]'

            placeholder='Актуальные файлы запроса'
            defaultValue={versionFilesList?.id}
            onChange={(e) => handleSelectChange(e)}
            options={optionsVersions}
          />
          <div>
            <label className='text-secondary'>Комментарий к запросу</label>
            <div>
              {versionFilesList?.comment || 'Комментарий к запросу отсутствует'}
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            {versionFilesList?.in_files?.map((elem: any) => {
              if (file_ids?.includes(elem?.id))
                return (
                  <div key={elem?.id} className='flex justify-between '>
                    <div className='flex flex-row gap-4'>
                      {getIcon(elem?.file_name)}
                      <Button
                        className='p-0 h-fit'
                        type='link'
                        onClick={() => downloadFile(elem?.id)}
                      >
                        {elem?.file_name}
                      </Button>
                    </div>
                    <Button
                      type='link'
                      htmlType='button'
                      className='p-0 underline underline-offset-4 h-fit'
                      onClick={() => handleDeleteFile(elem?.id)}
                    >
                      Удалить
                    </Button>
                  </div>
                );
            })}
          </div>
        </div>
        <Divider className='m-0'></Divider>

        <div className='w-3/5'>
          {!versionFilesList?.out_files?.length &&
          !versionFilesList?.operator_response ? (
            <Text type='secondary'>
              Оператор пока не добавил файлов в запрос, ваш запрос в обработке
            </Text>
          ) : (
            <div className='flex flex-col'>
              <Text type='secondary'>Ответ оператора</Text>
              <Text>{versionFilesList?.operator_response}</Text>
            </div>
          )}

          {versionFilesList?.out_files?.length > 0 && (
            <div className='flex flex-col mt-4 gap-2'>
              {versionFilesList?.out_files?.map((elem: any) => (
                <div key={elem?.id} className='flex justify-between '>
                  <div className='flex flex-row gap-4'>
                    {getIcon(elem?.file_name)}

                    <Button
                      className='p-0 h-fit'
                      type='link'
                      onClick={() => downloadFile(elem?.id)}
                    >
                      {elem?.file_name}
                    </Button>
                  </div>
                  {/* <Button
                    type='link'
                    htmlType='button'
                    className='p-0 underline underline-offset-4 h-fit'
                    onClick={() => console.log(1)}
                  >
                    Удалить
                  </Button> */}
                </div>
              ))}
            </div>
          )}
        </div>

        <Divider className='m-0'></Divider>

        <div className='flex flex-col gap-4 w-3/5'>
          <Text>
            Добавьте новые файлы для корректировки, после загрузки нажмите
            «Отправить документы». Мы оправим вам уведомление после того, как
            изучим обновленные документы.
          </Text>

          <Text type='secondary' className='mt-4'>
            Вы можете{' '}
            <Button
              type='link'
              htmlType='button'
              className='p-0 underline h-fit'
              onClick={() =>
                window.location.assign(
                  'https://storage.yandexcloud.net/aeropro-static/hovs_template.xlsx'
                )
              }
            >
              скачать шаблон Excel
            </Button>{' '}
            для ввода данных по системам ХОВС. Работайте в привычной среде:
            заполните файл, добавьте необходимые данные или скопируйте их из
            других документов. Когда все будет готово, загрузите файл через поле
            загрузки, и мы быстро обработаем его для дальнейшей работы.
          </Text>

          <FilesForm name={'files'} />

          <InputForm
            name='cloud_documents_url'
            label='Или добавьте ссылку на документ'
            placeholder='Введите значение'
          />
          <TextAreaForm
            rows={4}
            name='comment'
            label='Комментарий к запросу'
            placeholder='Расскажите подробнее'
          />
        </div>
        <Divider className='m-0'></Divider>

        <Button
          disabled={!buttonActive || isSending}
          type='primary'
          className='w-fit'
          onClick={submit}
        >
          {isSending && (
            <Spin indicator={<LoadingOutlined spin />} size='small' />
          )}
          Отправить документы
        </Button>
      </FormProvider>
    </div>
  );
};
